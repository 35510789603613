.blog-detail {
  .bg-blue-banner {
    background-color: #323232;
    height: 250px;
    width: 100%;
    position: absolute;
    z-index: -1;
  }
  .blog-image {
    height: auto;
    width: 400px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px dashed #e5e5e5;
    background-color: #f5f5f5;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    text-align: center;
  }
  .page-head {
    padding-top: 40px;
    padding-bottom: 40px;

    h1 {
      font-size: 30px;
      font-weight: 700;
      color: #fff;
    }

    p {
      margin-bottom: 0px;
      color: #888b9d;
      font-weight: 600;
    }

    .resp-start {
      justify-content: flex-end;
    }

    .import-btn {
      display: flex;
      align-items: center;
      padding: 8px 25px;
      background-color: #fff;
      font-size: 14px;
      color: #323232;
      font-weight: 600;
      border: 0px solid #dbdfea;

      .btn-icon {
        margin-right: 10px;
      }
    }

    .add-btn {
      background-color: #fff;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0px solid transparent;
      color: #323232;
      font-size: 14px;
      font-weight: 600;

      svg {
        margin-right: 10px;
      }
    }
  }

  .details {
    margin-bottom: 40px;
  }

  .blog-card {
    .blog-cardbody {
      .agent-profile-img {
        display: flex;
        align-items: center;

        .img-selection-box {
          height: 200px;
          width: 400px;
          cursor: pointer;
          border-radius: 5px;
          border: 2px dashed #e5e5e5;
          background-color: #f5f5f5;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          svg {
            margin-bottom: 10px;
          }

          p {
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            color: #323232;
            margin-bottom: 0px;
          }
        }

        .uploaded-image {
          height: 200px;
          width: 400px;
          border-radius: 5px;
          border: 2px dashed #e5e5e5;
          padding: 5px;
          margin-left: 10px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }

          .action-btns {
            position: absolute;
            top: -10px;
            right: -10px;

            .edit-btn {
              padding: 0px;
              border-radius: 50%;
              background-color: #fff;
              border: 2px solid #cd5828;
              padding: 2px 6px;
              margin-right: 5px;
            }

            .remove-btn {
              padding: 0px;
              border-radius: 50%;
              background-color: #fff;
              border: 2px solid #cd5828;
              padding: 2px 6px;
            }
          }
        }
      }
    }

    .blog-cardfooter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #fff;
      border-top: 0px solid transparent;
      padding-top: 0px;

      .primary-btn {
        width: max-content;
        padding: 10px 20px;
      }

      .cancel-btn {
        padding: 10px 20px;
        margin-right: 10px;
        background-color: #f5f5f5;
        border: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 700;
        color: #323232;
      }
    }
  }
}
